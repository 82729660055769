#docsPage {
  overflow-y: auto;

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 5fr;

  grid-template-areas:
    "header header"
    "sidebar content";

  width: 100%;
}
@media (max-width: 800px) {
  #docsPage {
    overflow-y: auto;

    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;

    grid-template-areas:
      "header"
      "content";

    width: 100%;
  }
  #docsPage #docsPageSidebar {
    display: none;
  }
}

#docsPageHeader {
  grid-area: header;
  justify-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

#docsPageBody {
  grid-area: content;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#docsPageSidebar {
  grid-area: sidebar;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#docsPageSidebar a {
  color: #1db954;
  text-decoration: none;
  font-size: 22px;
}
#docsPageSidebar ul {
  margin: 0;
  padding: 0;
}

#docsPageBody .docsBodyItemHeader {
  cursor: pointer;
  user-select: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#docsPageBody .docsBodyItemHeader svg {
  /*padding-top: 1rem;*/
  float: right;
  height: 0.5em;
}

#docsPageBody .docsBodyItem h1,
#docsPageBody .docsBodyItem h2,
#docsPageBody .docsBodyItem h3,
#docsPageBody .docsBodyItem h4,
#docsPageBody .docsBodyItem h5,
#docsPageBody .docsBodyItem h6 {
  text-align: left;
}
.docsBodyItemHeaderOpen {
  color: #1db954;
}
#docsPageBody .docsBodyItem:hover > h1,
#docsPageBody .docsBodyItem:hover > h2,
#docsPageBody .docsBodyItem:hover > h3,
#docsPageBody .docsBodyItem:hover > h4,
#docsPageBody .docsBodyItem:hover > h5,
#docsPageBody .docsBodyItem:hover > h6 {
  color: #1db954;
}


.docsBodyItem {
  padding-left: 1rem;
  padding-right: 1rem;
}

#docsPageBody .docsBodyItem {
  width: 100%;
}

.active-nav-link {
  font-weight: bold;
}

#docsPageBody .docsBodyItem:nth-of-type(even) {
  background-color: #2a2a2a;
}
#docsPageBody .docsBodyItem:nth-of-type(odd) {
  background-color: #3e3e3e;
}

#docsPageBody {
  background-color: #0b0b0b;
}
#docsPageSidebar {
  background-color: #0b0b0b;
}

#docsPageSidebar {
  padding: 0 1rem 1rem;
}


.docsBodyItemContent {
  transition-property: max-height;
  max-height: 0;
  overflow: hidden;
  transform-origin: top;
  transition-duration: 0.2s;
  padding-left: 0.5rem;
}
.docsBodyItemContentOpen {
  max-height: 1000px;
  height: auto;
}

.footer-link * {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
