@import '../../colors';

#header {
  z-index: 3;
}

#header {
  position: fixed;
  width: 100%;

  background: $black;

  border-bottom: 2px solid grey;
  padding-top: 10px;
  padding-bottom: 10px;

  padding-left: 0;

  display: grid;
  grid-template-columns: 5fr 2fr;
}