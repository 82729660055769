@import '../../colors';

.notificationDrawer {
  position: relative;
  cursor: pointer;
  svg {
    height: 40px;
    width: 40px;
  }
  svg.hoverDrawer {
    display: none;
  }

  text {
    font: 14px solid $green-1;
  }

  &:hover {
    svg:first-child {
      display: none;
    }
    svg.hoverDrawer {
      display: unset;
    }
  }
}

.notificationDrawerOpen {
  color: $green-1;
  filter: brightness(150%);
}

#badge {
  position: absolute;
  top: -12px;
  right: -7px;
  font-weight: bolder;
  #badgeCountNumber {
    user-select: none;
    position: absolute;
    top: 30px;
    right: 14px;
    color: white;
  }
}

.circle:before {
  content: "\25CF";
  font-size: 60px;
  color: $red;
}

.notificationsList {
  font-size: 2rem;
  position: absolute;
  top: 53px;
  right: 0;
  min-width: 40%;
  color: $black;
  max-height: 100vh;
  overflow: scroll;

  @media (max-width: 800px) {
    min-width: 100%;
  }
}

.rightHandSideHeader {
  grid-column: 2/3;
  display: flex;
  justify-content: flex-end;
}
.notifications {
  margin-right: 0.5rem;
}

.notification {
  margin-bottom: 0.5rem;
  padding: 1rem;
  display: flex;
  background: $grey-white;
}

.recoverable-notification {
  border-radius: 5px;
  box-shadow: 0px 0px 7px 2px $grey-white;


  background: $grey-white;
  filter: brightness(150%);
}

.notification {
  .leftPanel {
    flex: 80%;
  }
  .rightPanel {
    flex: 20%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    span {
      font-size: 1rem;
    }
  }
}

.notificationDetails {
  font-size: 1rem;
  margin-top: 1rem;
}

.icon-button {
  cursor: pointer;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

