.playlist-notification {
  display: flex;
  flex-direction: column;

  .playlist-notification-header {
    display: flex;
    align-items: center;
  }
}

.playlist-track-list {
  list-style: none;
  padding: 0;
  padding-left: 1rem;
  margin: 0;
  li {
    border-bottom: 1px;
  }
  height:60vh;
  overflow-y: scroll;
  padding-right: 1rem;
}
