@import url("~normalize.css");
@import 'colors.scss';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root,
#app,
.spotifyPanel {
  height: 100%;
  min-height: 100%;
  color: $white;
  background-color: $black;

  @media (max-width: 800px) {
    height: unset;
    min-height: unset;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html {
  svg {
    font-size: 3rem;
  }

  a {
    color: $grey-white;
    text-decoration: underline;
  }

  #compass {
    z-index: 1;
  }
}

.links {
  display: flex;
  > * {
    margin-left: 1rem;
  }
  justify-content: flex-start;
  align-items: flex-start;
}

* {
  z-index: 2;
}

#helpPlaylist {
  color: $purple;
  cursor: pointer;
  height: 3rem;
}

#compass {
  position: fixed;
  bottom: -100px;
  right: -100px;
  opacity: 0.4;
  pointer-events: none;
}

.green-link {
  color: $green-1;
  cursor: pointer;
  text-decoration: none;
}

.loadingBar {
  background-color: $green-1;
  height: 5px;
  position: fixed;
  top: 0;
  z-index: 20;
}

.app {
  background-color: $black;
}

.spotifyPanel {
  display: grid;
  grid-template-rows: 73px minmax(max-content, 1fr) minmax(max-content, 1fr);
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "head head" "main playlistActions" "main playlistActions";
  user-select: none;
  @media (max-width: 800px) {
    grid-template-rows: 50px auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas: "head" "playlistActions" "details" "main";
  }
}

.plainPanel {
  display: grid;
  grid-template-rows: 8rem minmax(max-content, 1fr);
  grid-template-areas: "head" "main";

  #header {
    grid-area: head;
  }

  .plainBody {
    grid-area: main;
  }
}


.userName {
  text-align: right;
  color: $white;
  font-size: 10px;

  img {
    object-fit: cover;
    border-radius: 100%;
    height: 40px;
    width: 40px;
  }
}

.spotifyPanel {
  .playlistView {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.playlistView {
  grid-area: playlistActions;

  //padding: 1rem;

  display: flex;
  flex-direction: column;

  h4 {
    display: flex;
    align-items: center;
  }

  .mobile {
    @media(min-width: 800px) {
      display: none;
    }
  }

  .desktop {

    @media(max-width: 800px) {
      display: none;
    }
  }
}

.detailsView {
  grid-area: details;

  padding-left: 1rem;
  padding-right: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 1rem;
  @media (max-width: 800px) {
    margin-top: 5rem;
  }

  .genres {
    flex-basis: 20%;
    flex-shrink: 0;
    flex-grow: 0;
  }
}

@media (min-width: 800px) {
  .mobileTitle {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;

  text-align: left;
  border-radius: 1rem;
  padding: 1rem;

  letter-spacing: 1px;
  line-height: 1.5;
  font-weight: 400;

  display: flex;
}

.centeredHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

p,
button,
li {
  line-height: 1.5;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

p {
  text-indent: 0;
}

h4,
h5,
h6 {
  font-weight: 100;
}

h5,
h6 {
  color: $white;
}

h1 {
  font-size: 4rem;
  @media (max-width: 800px) {
    font-size: 3.5rem;
  }
}

h2 {
  font-size: 3.5rem;
  padding: 0.8rem;

  @media (max-width: 800px) {
    font-size: 3rem;
  }
}

h3 {
  font-size: 3rem;
  padding: 0.6rem;

  @media (max-width: 800px) {
    font-size: 2.5rem;
  }
}

h4 {
  font-size: 2.5rem;
  padding: 0.4rem;

  @media (max-width: 800px) {
    font-size: 2rem;
  }
}

h5 {
  font-size: 2rem;
  padding: 0.2rem;

  @media (max-width: 800px) {
    font-size: 1.8rem;
  }
}

h6 {
  font-size: 1.2rem;
  padding: 0.1rem;

  @media (max-width: 800px) {
    font-size: 1.6rem;
  }
}

@media (max-width: 800px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
}

.playbackStatus {
  color: $green-1;
  grid-area: main;

  display: flex;
  flex-direction: column;

  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 200px;
  border-right: grey 2px solid;

  @media (max-width: 800px) {
    border: none;
  }
}


.genres,
.relatedArtists {
  text-align: center;
}

.spotifyLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  img {
    width: 100px;
  }

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    display: inline;
  }
}


.left-hand-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  img {
    margin-left: 1rem;
  }
}


#songCompassLogo {
  height: 30px;
  @media (min-width: 800px) {
    height: 40px;

  }
}

#spotifyLogo {
  height: 20px;
  @media (min-width: 800px) {
    height: 30px;
  }
}


.popularityBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-color: $lightPurple;

  border-radius: 3px;
}

.emphasis,
.emphasis2 {
  letter-spacing: 2px;
  font-variant: all-small-caps;
  font-size: 1.3rem;
  text-decoration: underline;
}

svg.hoverableSvg:hover {
  opacity: 0.8;
  cursor: pointer;
}

svg.isSvgToggled {
  color: $orange-red;
}

.toggledHighlight {
  color: $orange-red;
}

.highlightGenre {
  background-color: $x-light-purple;
}
