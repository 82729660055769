.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  z-index: 1000;

  svg{
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
}