.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-left: 2rem;
  margin-right: 2rem;


  padding-top: 3rem;

  >* {
    text-align: center;
  }
}


.device-list {
  list-style: none;
}


.icon-with-margin {
  margin-left: 1rem;
}