.artist-image-wrapper{
  position: fixed;
  display: flex;

  top: 20%;
  left: 30%;

  width: 500px;
  height: 500px;
  @media(max-width:800px){
    width: 100%;
    height: unset;
    top: 300px;
    left:0%;
  }

  z-index: 1;
}
.artist-image {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  box-shadow: 0 0 8px 8px black inset;
  border-radius: 100%;
}

.artist-image-expanded {
  width: 100vw;
  height: 100vh;
  opacity: 1;
}

.image-expander {
  color: red;
  opacity: 1;

  position: relative;
  top: 5rem;
  left: 50%;
  right: 50%;
  display: inline-block;
  cursor: pointer;
  z-index: 400;
}

