
$white: #e4e4e4;
$grey-white: #e6eaf3;
$grey: grey;

$black: #1d1d1d;
$black-light: #303030;
$black-lighter: #363636;

$purple: purple;
$lightPurple: #da00ff;
$x-light-purple: #de34de;

$green-1: #1db954;
$orange-red: orangered;
$red: red;
$pink: #fda4c2;
