@import '../../colors';

.rr-header {
}

.rr-body {
  display: flex;
  flex-direction: column;
  @media(min-width: 1000px){
    max-width: 500px;
  }
}
.rr-body {

}
.rr-whats-playing {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rr-controls {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .action-button {
    font-size: 1rem;
  }

  > * {
    width: 29%;
  }
}

.rr-previous {
  color: deeppink;
}

.rr-next {
  color: deepskyblue;
}

.rr-bomb {
  color: #1db954;
}

.rr-like-button {
  color: #ff0925;
}

.rr-like-button,
.rr-next,
.rr-bomb,
.rr-play,
.rr-pause,
.rr-previous {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 7rem;
    height: 7rem;
  }
}

.wrapper-progress-bar {
  width: 100%;
  height: 10px;
  background: $grey-white;

  .inner-progress-bar {
    height: 100%;
    background: $green-1;
  }
}

.progress-ring {
  position: absolute;
  top: 27px;
}

.rapid-radio-artist-details {
  display: flex;
  flex-direction: row;
}

.rapid-radio-main {
  display: flex;
  flex-direction: row;
  margin-top: -4rem;
  @media(max-width: 800px){
    flex-direction: column;
  }
}

.rapid-radio-first-column {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.rapid-radio-second-column {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.rapid-radio-third-column {
  width: 40%;
  display: flex;
  flex-direction: column;
}


.rapid-radio-main > * {
  @media(max-width: 800px){
    width:100%;
  }
}